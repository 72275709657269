import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

//images
import PremFinanceImgBtm from "../images/prem-finance-hero-bottom.png"
// import GoldenStripImg from "../images/golden-strip.svg"
import PremFinanceTeam from "../images/prem-finance-team.png"
import GoldenBoxImg from "../images/golden-box.svg"
import AgentIcon1 from "../images/agent-app-icon-free.svg"
import AgentIcon2 from "../images/agent-app-icon-not-shared.svg"
import AgentIcon3 from "../images/agent-app-icon-no-oblig.svg"

//data
import { PremiumFinanceData } from "../components/data/data"

const PremiumFinance = () => {
  return (
    <Layout>
      <SEO
        title="Premium Finance - National Partners"
        description="A Dedicated Agent Support Team Once you get started with the application process, you’ll be assigned an Agent Services Manager who is always there to help answer any questions and provide support. National Partners will work together with you to help you achieve your insurance premium finance goals. And our Agent Services team is just […]"
      />
      <div className="page-headline">
        <div className="hero-headline premium-finance-page">
          <div className="container">
            <div className="row">
              {/* <div className="section-heading text-center">
                <div className="hero-body"> */}
              <div className="col-lg-8 mx-lg-auto">
                <h1 className="hero-page-title">
                  National Partners Premium Finance Services
                </h1>
              </div>
              <div className="col-lg-9 mx-lg-auto">
                <h2 className="hero-subtitle text-white">
                  Premium Finance offers clients the ability to preserve capital
                  by spreading out their insurance premiums. Although it is a
                  small part of what is done for a client, it is essential in
                  that it must be competitive, efficient, and painless.
                </h2>
              </div>
              {/* </div>
              </div> */}
            </div>
          </div>
        </div>
        <img
          src={PremFinanceImgBtm}
          className="hero-bottom-img img-fluid w-100"
          alt="Meeting"
        />
      </div>
      <div className="page-content pb-280 pt-0 premium-finance-wrapper">
        <section style={{ paddingBottom: "6rem", paddingTop: "3rem" }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-heading text-center mb-5">
                  <h6 className="section-subtitle text-gold text-uppercase">
                    premium finance
                  </h6>
                  <hr className="section-hr" />
                  <h2 className="section-title pb-0">
                    The National Partners Approach
                  </h2>
                </div>
              </div>
              <div className="col-lg-4 col-md-10 px-lg-4 mx-lg-0 mx-md-auto mx-auto col-10">
                <p className="section-description text-justify">
                  We bring our extensive knowledge and experience to the table
                  in order to satisfy the needs of our agents and brokers.
                  Expect courteous and professional service from our highly
                  qualified team as well as easy-to-use software for management
                  of premium finance requirements.
                </p>
              </div>
              <div className="col-lg-4 col-md-10 col-10 px-lg-4 mx-lg-0 mx-md-auto mx-auto mt-lg-0 mt-md-4 mt-4">
                <p className="section-description text-justify">
                  The agents and brokers we partner with can enjoy flexible
                  terms and reasonable rates. We provide our services across the
                  whole country and our sales and services team are on stand-by
                  to provide the necessary knowledge and assistance for your
                  commercial insurance premium financing needs.
                </p>
              </div>
              <div className="col-lg-4 col-md-10 col-10 px-lg-4 mx-lg-0 mx-md-auto mx-auto mt-lg-0 mt-md-4 mt-4">
                <p className="section-description text-justify">
                  With National Partners, you are guaranteed with a
                  user-friendly experience, excellent financing options, and
                  stress-free processing. Agent application is designed to be
                  smooth and quick. Preserve capital, retain funds, and expand
                  your investments through the right insurance premium financing
                  services.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="pt-0">
          <article className="blue-bg-wrapper">
            <div className="blue-bg" />
          </article>
          <article className="contact-section-wrapper">
            <div className="container">
              <div className="row mx-lg-0 mx-md-0 mx-3 py-lg-5 px-lg-5 py-md-5 px-md-5 px-3 py-5">
                <div className="col-lg-5 col-md-12 pr-lg-5">
                  <p className="section-description">
                    Regardless of the size of your agency, brokerage, or
                    business - National Partners is a premium financing company
                    that will prioritize your requirements. Enjoy faster
                    turn-around times, consistently high-quality service, as
                    well as flexible terms.
                  </p>
                  <p className="section-description">
                    Once you get started with the application process, you'll be
                    assigned an Agent Services Manager who is always there to
                    help answer any questions and provide support. National
                    Partners will work together with you to help achieve your
                    insurance premium finance goals. And our Agent Services team
                    is just an email, or a phone call away if you have
                    questions.
                  </p>
                </div>
                <div className="col-lg-7 col-md-12 align-self-center mt-lg-0 mt-md-5 mt-5">
                  <div className="team-img-wrapper">
                    <div className="team-img">
                      <img
                        src={PremFinanceTeam}
                        alt="Team"
                        className="img-fluid"
                      />
                    </div>
                    <div className="golden-box-img align-self-end">
                      <img
                        src={GoldenBoxImg}
                        alt="Golden Img"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-12 text-center"
                  style={{ marginTop: "5rem" }}
                >
                  <Link to="/contact-us" className="mx-auto">
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
          </article>
        </section>
        <section id="benefit" className="py-0 choose-np-wrapper">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="section-heading text-center mb-5">
                  <h6 className="section-subtitle text-gold text-uppercase">
                    how you benefit
                  </h6>
                  <hr className="section-hr" />
                  <h2 className="section-title mb-0 mt-5">
                    Why choose to work with National Partners?
                  </h2>
                  <p className="section-main-description">
                    Here are the main benefits you can take full advantage of:
                  </p>
                </div>
              </div>
              {PremiumFinanceData.map(premium => (
                <div
                  className="col-lg-4 col-md-6 col-10 mt-lg-5 mt-md-5 mt-5"
                  style={{ maxHeight: "auto" }}
                >
                  <div className="choose-np-boxes">
                    <div
                      className="d-flex justify-content-start align-items-center"
                      style={{ height: "25%" }}
                    >
                      <div className="mr-2">
                        <span>{premium.step}</span>
                      </div>
                      <h4 className="mb-0 py-0">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: premium.title,
                          }}
                        />
                      </h4>
                    </div>
                    <hr className="my-3" />
                    <p className="choose-np-description">
                      {premium.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        <section className="mb-0  pb-0 agent-application-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 text-center">
                <div className="section-heading">
                  <h6 className="section-subtitle text-gold text-uppercase">
                    Are You Ready to Start?
                  </h6>
                  <hr className="section-hr" />
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-lg-6 col-md-10 mx-lg-0 mx-md-auto agent-application-box">
                <div className="section-heading text-center mb-5">
                  <h2 className="section-title">
                    Agent Application is Quick and Easy
                  </h2>
                  <p className="section-main-description">
                    National Partners is one of the leading insurance premium
                    financing providers across the US.
                  </p>
                  <p className="section-description py-lg-5 py-md-5 py-4">
                    We are dedicated to serve you well, so give us a chance to
                    show you how our team and services can make a difference for
                    your business.
                  </p>
                  <p className="section-description">
                    To know more about our services, send us an email at
                    <a
                      href="mailto:info@nationalpartners.com"
                      className="mx-1"
                      style={{ color: "#000", opacity: 1, fontWeight: "bold" }}
                    >
                      <u>info@nationalpartners.com</u>
                    </a>
                    or give us a call at
                    <a
                      href="tel:800-506-8901"
                      className="mx-1"
                      style={{ color: "#000", opacity: 1, fontWeight: "bold" }}
                    >
                      <u>800-506-8901.</u>
                    </a>
                  </p>
                  <p className="section-description mt-4">
                    We look forward to hearing from you.
                  </p>
                </div>
              </div>
              <div className="col-lg-5 col-md-9 col-12 mx-lg-0 mx-md-auto mr-lg-auto mt-lg-0 mt-md-5">
                <div
                  className="d-flex align-items-center"
                  style={{
                    background:
                      "linear-gradient(180deg, #F1F6FF 0%, #FFFFFF 100%)",
                    boxShadow:
                      "0px 0px 15px rgba(0, 0, 0, 0.1), inset 0px 4px 4px #FFFFFF",
                    borderRadius: "100px 10px 10px 100px",
                    padding: "0.5rem",
                  }}
                >
                  <img
                    src={AgentIcon1}
                    alt="The application process is FREE."
                  />
                  <p
                    className="ml-4 mb-0"
                    style={{
                      fontFamily: "Lato",
                      fontStyle: "normal",
                      fontWeight: "normal",
                      fontSize: "20px",
                      lineHeight: "30px",
                    }}
                  >
                    The application process is FREE.
                  </p>
                </div>
                <div
                  className="d-flex align-items-center w-100 justify-content-end mt-lg-5 mt-md-5 mt-5"
                  style={{
                    background:
                      "linear-gradient(180deg, #F1F6FF 0%, #FFFFFF 100%)",
                    boxShadow:
                      "0px 0px 15px rgba(0, 0, 0, 0.1), inset 0px 4px 4px #FFFFFF",
                    borderRadius: "10px 100px 100px 10px",
                    padding: "0.5rem",
                  }}
                >
                  <p
                    className="mr-4 mb-0"
                    style={{
                      fontFamily: "Lato",
                      fontStyle: "normal",
                      fontWeight: "normal",
                      fontSize: "20px",
                      lineHeight: "30px",
                    }}
                  >
                    Your information is NOT shared.
                  </p>
                  <img
                    className="align-self-end"
                    src={AgentIcon2}
                    alt="Your information is NOT shared."
                  />
                </div>
                <div
                  className="d-flex align-items-center mt-lg-5 mt-md-5 mt-5"
                  style={{
                    background:
                      "linear-gradient(180deg, #F1F6FF 0%, #FFFFFF 100%)",
                    boxShadow:
                      "0px 0px 15px rgba(0, 0, 0, 0.1), inset 0px 4px 4px #FFFFFF",
                    borderRadius: "100px 10px 10px 100px",
                    padding: "0.5rem",
                  }}
                >
                  <img src={AgentIcon3} alt="There is NO obligation." />
                  <p
                    className="ml-4 mb-0"
                    style={{
                      fontFamily: "Lato",
                      fontStyle: "normal",
                      fontWeight: "normal",
                      fontSize: "20px",
                      lineHeight: "30px",
                    }}
                  >
                    There is NO obligation.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default PremiumFinance
